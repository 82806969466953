.root {
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

.fieldSelectContainer {
  display: flex;
  background: var(--card-background);
  margin-bottom: 8px;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
}

.fieldSelect {
  margin: 0 8px;
  font-size: inherit;
  font-family: inherit;
  background: transparent;
  color: var(--background-contrast);
  border-radius: 4px;
  border: 1px solid var(--background-contrast);
  padding: 4px;
  box-sizing: border-box;

  cursor: pointer;
}

.priceHeader {
  font-size: 32px;
  text-align: center;
  width: 100%;
  font-weight: 500;
}

.iconButton {
  margin: 0 8px;
  background: inherit;
  color: var(--background-contrast);
  /* border: 2px solid #fff; */
  border-radius: 1px;
  border: 1px solid var(--background-contrast);
  font-size: inherit;
  border-radius: 4px;
  padding: 4px;
  box-sizing: border-box;
  min-width: 42px;
  cursor: pointer;
  margin-left: auto;
}

.iconButtonFilled {
  background: var(--background-contrast);
  color: var(--text-dark);
}
