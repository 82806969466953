.root {
  width: 100%;
  max-width: 2400px;
  min-height: 100%;
  background: var(--background);
}

.strongPicksContainer {
  height: 30px;
}

.selectMenu {
  position: absolute;
  top: calc(100% + 8px);
  left: auto;
  right: auto;
  width: 100%;
  max-width: 500px;
  max-height: 300px;
  overflow-y: auto;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 4px;
  background: var(--card-background);
  z-index: 1;
}

.selectOption {
  font-size: 18px;
  padding: 8px 24px;
  text-align: left;
  border: none;
  border-bottom: 1px solid #eee;
  width: 100%;
  background: var(--card-background);
  cursor: pointer;
  transition: 100ms;
  color: inherit;
}
.selectOption:hover {
  background: #eee;
  color: #333;
}

.searchContainer {
  position: relative;
  width: 100%;
  max-width: 500px;
  margin: auto;
  background-color: var(--background);
}

.searchIcon {
  position: absolute;
  left: 0;
  bottom: 2px;
  width: 30px;
}

.search {
  background-color: var(--background);
  color: var(--text-primary);
  max-width: 600px;
  width: 100%;
  outline: none;
  border: none;
  font-size: 24px;
  padding: 4px 8px 4px 36px;
  border-bottom: 2px solid var(--t-primary);
  box-sizing: border-box;
  transition: 200ms;
}
.search:focus {
  border-bottom: 2px solid var(--t-primary);
}

.companyContainer {
  padding: 32px;
  margin: auto;
}

.companyName {
  font-size: 32px;
  font-weight: 500;
  margin: 0;
}

/* Scrollbar */
/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--card-background);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
