.fieldsContainer {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  border-radius: 4px;
  padding: 12px;
  background: var(--card-background);
  margin-right: 16px;
  max-height: 600px;
  overflow: hidden;
  overflow-y: auto;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 0px 8px 0px;
  padding: 8px;
  border: 1px solid var(--text-primary);
  width: 150px;
  border-radius: 4px;
  cursor: pointer;
  transition: 300ms;
}
.item:hover {
  box-shadow: rgba(204, 204, 204, 0.3) 0px 1px 2px 0px,
    rgba(204, 204, 204, 0.15) 0px 1px 3px 1px;
}

.itemLabel {
  border-bottom: 1px solid;
  width: 100%;
  padding-bottom: 6px;
  margin-bottom: 6px;
  font-size: 20px;
}

.pinnedItem {
  background: #ccc;
  color: var(--text-dark);
  font-weight: 400;
  border-color: #ccc;
  box-shadow: #ccc 0px 3px 8px;
  box-shadow: #ccc 0px 1px 2px 0px, #bbb 0px 1px 3px 1px;
  box-shadow: rgba(204, 204, 204, 0.3) 0px 1px 2px 0px,
    rgba(204, 204, 204, 0.15) 0px 1px 3px 1px;
}
